/*==========================
Progressbar Area 
============================*/
.rn-progress-bar{
    .single-progress{
        position: relative;
        margin-top: 38px;
        .title{
            font-size: 15px;
            color: $heading-color;
            @extend %fontWeight500;
            margin-bottom: 10px;
        }
        .progress{
            height: 4px;
            border-radius: 4px;
            box-shadow: none;
            background-color: #f5f7fd;
            overflow: visible;
            .progress-bar{
                background-color: #f81f01;
                background-image: linear-gradient(-224deg,#f81f01 0,#ff9473 100%);
                position: relative;
                color: #ff9473;
            }
        }

        .label{
            position: absolute;
            right: 0;
            top: 0;
            @extend %fontWeight500;
            color: $heading-color;
            font-size: 15px;
            @media #{$sm-layout} {
                top: -8px;
            }
        }
        
        &.custom-color--1 {
            .progress{
                .progress-bar{
                    color: rgb(97, 253, 237);
                    background-color: rgb(13, 138, 188);
                    background-image: linear-gradient(-224deg, rgb(13, 138, 188) 0px, rgb(97, 253, 237) 100%);
                }
            }
        }

        &.custom-color--2 {
            .progress{
                .progress-bar{
                    color: rgb(238, 206, 144);
                    background-color: rgb(212, 85, 41);
                    background-image: linear-gradient(-224deg, rgb(212, 85, 41) 0px, rgb(238, 206, 144) 100%);
                }
            }
        }

        &.custom-color--3 {
            .progress{
                .progress-bar{
                    color: rgb(92, 81, 255);
                    background-color: rgb(246, 70, 169);
                    background-image: linear-gradient(-224deg, rgb(246, 70, 169) 0px, rgb(92, 81, 255) 100%);
                }
            }
        }

        &.custom-color--4 {
            .progress{
                .progress-bar{
                    color: rgb(229, 82, 154);
                    background-color: rgb(231, 118, 84);
                    background-image: linear-gradient(-224deg, rgb(231, 118, 84) 0px, rgb(229, 82, 154) 100%);
                }
            }
        }
    }


    &.progress-bar--2 {
        .single-progress {
            .progress {
                height: 1px;
                border-radius: 1px;
            }
        }
    }

    &.progress-bar--3 {
        .single-progress {
            .progress {
                height: 20px;
                border-radius: 0;
            }
        }
    }

    &.style-dots{
        .single-progress{
            .progress{
                height: 32px;
                border: 2px solid #f2f2f2;
                padding: 4px;
                border-radius: 30px;
                box-shadow: none;
                background: none;
                overflow: visible;
                .progress-bar{
                    position: relative;
                    top: auto;
                    bottom: auto;
                    left: auto;
                    right: auto;
                    background-color: transparent !important;
                    background-size: 25px 25px;
                    background-repeat: repeat-x;
                    background-position: -3px center;
                    background-image: radial-gradient(ellipse at center,#5945e6 50%,transparent 55%);

                    &::before ,
                    &::after{
                        display: none;
                    }
                   
                }
            }
        }
    }

    &.style-solid{
        .single-progress{
            .progress{
                height: 32px;
                padding: 6px;
                border-radius: 30px;
                box-shadow: none;
                background-color: #f2f2f2;
                .progress-bar{
                    position: relative;
                    top: auto;
                    bottom: auto;
                    left: auto;
                    right: auto;
                    border-radius: inherit;
                    background-color: #f81f01;
                    background-image: linear-gradient(-224deg,#f81f01 0,#ff9473 100%);
                    &::before ,
                    &::after{
                        display: none;
                    }
                   
                }
            }

            &.custom-color--1{
                .progress{
                    .progress-bar{
                        color: rgb(97, 253, 237);
                        background-color: rgb(13, 138, 188);
                        background-image: linear-gradient(-224deg, rgb(13, 138, 188) 0px, rgb(97, 253, 237) 100%)
                    }
                }
            }

            &.custom-color--2 {
                .progress{
                    .progress-bar{
                        color: rgb(238, 206, 144);
                        background-color: rgb(212, 85, 41);
                        background-image: linear-gradient(-224deg, rgb(212, 85, 41) 0px, rgb(238, 206, 144) 100%);
                    }
                }
            }

            &.custom-color--3 {
                .progress{
                    .progress-bar{
                        color: rgb(92, 81, 255);
                        background-color: rgb(246, 70, 169);
                        background-image: linear-gradient(-224deg, rgb(246, 70, 169) 0px, rgb(92, 81, 255) 100%);
                    }
                }
            }

            &.custom-color--4 {
                .progress{
                    .progress-bar{
                        color: rgb(229, 82, 154);
                        background-color: rgb(231, 118, 84);
                        background-image: linear-gradient(-224deg, rgb(231, 118, 84) 0px, rgb(229, 82, 154) 100%);
                    }
                }
            }


        }
    }
}
